import React from 'react';

const Contact = () => {
    return (
        <div className="mx-auto px-6 py-12 text-[#EEEEEE] bg-[#222831]">
            <h2 className="text-5xl font-bold mb-8 text-center text-[#00ADB5]">Contact Us</h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                {/* Contact Information Cards */}
                <div className="p-6 bg-[#393E46] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center border-l-4 border-[#00ADB5]">
                    <h3 className="text-2xl font-semibold mb-2 text-[#00ADB5]">Address</h3>
                    <p className="text-lg text-[#EEEEEE]">17A SHIKHALI GURBANOV, Khirdalan 0100</p>
                </div>

                <div className="p-6 bg-[#393E46] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center border-l-4 border-[#00ADB5]">
                    <h3 className="text-2xl font-semibold mb-2 text-[#00ADB5]">Phone</h3>
                    <p className="text-lg text-[#EEEEEE]">+994 51 911 50 30</p>
                    <p className="text-lg text-[#EEEEEE]">+994 50 332 51 23</p>
                </div>

                <div className="p-6 bg-[#393E46] rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 text-center border-l-4 border-[#00ADB5]">
                    <h3 className="text-2xl font-semibold mb-2 text-[#00ADB5]">Email</h3>
                    <p className="text-lg">
                        <a href="mailto:rizvan.karimov@iss.az" className="text-[#00ADB5] hover:underline">
                            rizvan.karimov@iss.az
                        </a>
                    </p>
                </div>
            </div>

            {/* Embedded Google Map */}
            <div className="mt-12">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d516.0385581867057!2d49.754824399663875!3d40.45634233584842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e1!3m2!1sen!2saz!4v1730824143026!5m2!1sen!2saz"
                    title="Location map for ISS office in Baku"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen
                    ariaHidden="false"
                    tabIndex="0"
                    className="rounded-lg shadow-xl border-t-4 border-[#00ADB5]"
                />
            </div>
        </div>
    );
};

export default Contact;
