import React from 'react';

const About = () => {
    return (
        <div className="mx-auto px-6 py-12 text-[#EEEEEE] bg-[#222831]">
            <h2 className="text-5xl font-bold mb-8 text-center text-[#00ADB5]">About Us</h2>
            <div className="bg-[#393E46] rounded-lg p-8 shadow-lg hover:shadow-xl transition-shadow duration-300">
                <p className="text-lg mb-6 text-[#EEEEEE] leading-relaxed">
                    <span className="font-semibold text-[#00ADB5]">ISS (Intelligent Services & Solutions) LLC</span> has been a dedicated force in facade engineering for the past year. As a company built on a foundation of expertise and innovation, ISS has quickly earned a reputation for excellence in delivering advanced solutions for building exteriors worldwide. From large-scale urban projects to tailored solutions for individual clients, we bring expertise and creativity to every endeavor.
                </p>
                <p className="text-lg mb-6 text-[#EEEEEE] leading-relaxed">
                    At ISS, our primary focus is on <span className="font-semibold text-[#00ADB5]">customer satisfaction</span> and upholding the highest standards of <span className="font-semibold text-[#00ADB5]">safety</span> across all operations. We believe that excellence is achieved not only through the quality of our work but also through the relationships we build with our clients. Our team of experienced engineers, designers, and project managers is committed to meeting the unique needs of each project, ensuring that every facade we create reflects both functionality and aesthetic appeal.
                </p>
                <p className="text-lg mb-6 text-[#EEEEEE] leading-relaxed">
                    ISS places great emphasis on safety, implementing rigorous standards for both equipment and procedures. We understand the critical importance of safety in the construction and facade industry, and we are dedicated to protecting the well-being of our team, clients, and the surrounding communities. Our procedures are designed to exceed industry standards, ensuring each project is completed securely and responsibly.
                </p>
                <p className="text-lg text-[#EEEEEE] leading-relaxed">
                    As we continue to grow, ISS remains dedicated to pushing the boundaries of facade engineering, blending innovation with reliability to help shape the future of modern architecture.
                </p>
            </div>
        </div>
    );
};

export default About;
