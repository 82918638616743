import React from 'react';

const Services = () => {
    return (
        <div>
            <h2>Our Services</h2>
            <ul>
                <li>Facade Design</li>
                <li>Structural Engineering</li>
                <li>Construction Management</li>
                <li>3D Modelling</li>
            </ul>
        </div>
    );
};

export default Services;
