import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-[#222831] text-[#EEEEEE] py-4 text-center">
            <p className="text-sm">
                &copy; 2024 ISS. All rights reserved.
            </p>
        </footer>
    );
};

export default Footer;
