import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    return (
        <nav className="bg-[#222831] p-4 shadow-md">
            <ul className="flex justify-center space-x-8">
                <li>
                    <Link
                        to="/"
                        className="text-[#EEEEEE] hover:text-[#00ADB5] font-semibold transition-colors duration-200"
                    >
                        Home
                    </Link>
                </li>
                <li>
                    <Link
                        to="/about"
                        className="text-[#EEEEEE] hover:text-[#00ADB5] font-semibold transition-colors duration-200"
                    >
                        About
                    </Link>
                </li>
                {/* <li>
                    <Link
                        to="/services"
                        className="text-[#EEEEEE] hover:text-[#00ADB5] font-semibold transition-colors duration-200"
                    >
                        Services
                    </Link>
                </li> */}
                <li>
                    <Link
                        to="/contact"
                        className="text-[#EEEEEE] hover:text-[#00ADB5] font-semibold transition-colors duration-200"
                    >
                        Contact
                    </Link>
                </li>
                {/* <li>
                    <Link
                        to="/studio"
                        className="text-[#EEEEEE] hover:text-[#00ADB5] font-semibold transition-colors duration-200"
                    >
                        Studio
                    </Link>
                </li> */}
            </ul>
        </nav>
    );
};

export default Navbar;
