import React, { useState, useRef } from 'react';

const Home = () => {
    const [showModal, setShowModal] = useState(false);
    const videoRef = useRef(null);

    const openVideoModal = () => {
        setShowModal(true);
        if (videoRef.current) {
            videoRef.current.currentTime = 0; // Start video from the beginning
            videoRef.current.play().catch((error) => {
                console.log("Autoplay might be prevented by the browser:", error);
            });
        }
    };

    const closeVideoModal = () => {
        setShowModal(false);
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <>
            {/* Hero Section */}
            <div className="relative bg-cover bg-center h-screen flex items-center">
                {/* Background Video */}
                <video
                    className="absolute inset-0 object-cover w-full h-full"
                    src="/videos/iss-esas-p52vti.hevc.mp4?cache_bypass=1"
                    loop
                    muted
                    autoPlay
                    playsInline
                />

                {/* Overlay */}
                <div className="absolute inset-0 bg-[#000000] bg-opacity-70"></div>

                {/* Content */}
                <div className="container mx-auto px-6 relative z-10">
                    <div className="max-w-lg">
                        <h2 className="text-[#00ADB5] text-4xl font-bold">ISS - Intelligent Services & Solutions</h2>
                        <h1 className="text-[#EEEEEE] text-6xl font-extrabold mb-4">Automated Facade Cleaning</h1>
                        {/* Clickable Video Trigger */}
                        <button
                            onClick={openVideoModal}
                            className="bg-[#00ADB5] text-[#EEEEEE] py-2 px-4 rounded hover:bg-[#00ADB5] hover:text-[#EEEEEE] transition"
                        >
                            Watch Full Video
                        </button>
                    </div>
                </div>
            </div>

            {/* Video Modal */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                    <div className="relative w-full max-w-4xl">
                        <button
                            onClick={closeVideoModal}
                            className="absolute top-0 right-0 m-4 text-[#EEEEEE] text-2xl z-50"
                        >
                            &times;
                        </button>
                        <video
                            ref={videoRef}
                            src="/videos/iss-esas-p52vti.hevc.mp4?cache_bypass=1"
                            controls
                            autoPlay
                            controlsList="nodownload"
                            className="w-full rounded-lg"
                        />
                    </div>
                </div>
            )}

            {/* Business Hours Section */}
            <section className="py-12 bg-[#222831] text-[#EEEEEE]">
                <div className="container mx-auto px-6">
                    {/* Content */}
                    <div className="flex flex-wrap -mx-4">
                        {/* Business Hours Section */}
                        <div className="w-full md:w-1/2 p-4">
                            <div
                                className="bg-cover bg-center h-full rounded-lg shadow-lg"
                                style={{ backgroundImage: `url('/images/photo-3.2.jpg')` }}
                            >
                                <div className="p-8 bg-[#393E46] bg-opacity-90 rounded-lg shadow-lg">
                                    <h2 className="text-2xl font-bold mb-4 text-[#00ADB5]">Business Hours</h2>
                                    <div className="text-lg mb-6 text-[#EEEEEE]">
                                        <h4 className="font-semibold">Opening Days:</h4>
                                        <p><strong>Monday – Friday:</strong> 9am to 6pm</p>
                                        <p>
                                            If the wind speed exceeds <strong>30 km/h</strong> and the height of the building is more than <strong>10 meters</strong>, the work is stopped.
                                        </p>
                                        <h4 className="font-semibold mt-4">Vacations:</h4>
                                        <p>All Saturday and Sunday</p>
                                        <p>All Official Holidays</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Welcome Section */}
                        <div className="w-full md:w-1/2 p-4">
                            <div className="p-8 bg-[#393E46] rounded-lg shadow-lg">
                                <h2 className="text-4xl font-bold mt-4 text-[#00ADB5]">ISS - Intelligent Services & Solutions</h2>
                                <p className="mt-4 text-lg text-[#EEEEEE]">
                                    ISS (Intelligent Services & Solutions) LLC has been operating for one year. We always adhere to high-level safety rules. We have completed many successful projects in a short period, focusing strongly on customer satisfaction.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
